import { Injectable } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { Injector } from "@angular/core";

import { ILiveIssueR } from "../interfaces/LiveIssueR";
import { IPlaybackAction } from "../interfaces/PlaybackAction";
import { SessionSocketService } from "./session-socket.service";

@Injectable({
  providedIn: "root",
})
export class RecordingService {
  isRecording = false;
  isPromptRecording = false;

  isFreshRecordingStarted = new BehaviorSubject<boolean>(false);
  skipRecording = new BehaviorSubject<boolean>(false);
  isRecordedEnded = new BehaviorSubject<boolean>(false);
  onboardingComplete = new BehaviorSubject<boolean>(false);
  firstTestRecorded = new BehaviorSubject<boolean>(false);
  IssuesCreated = new BehaviorSubject<ILiveIssueR[]>([]);
  isAutoDialogHandle: boolean = true;
  isHighPromptMode: boolean = false;
  ManualRecording$?: Subscription;
  continueAppSwitch?: any;
  switchAppMood: any;
  manualStepsRecorded;
  public automatedStepsRecorded = new BehaviorSubject<IPlaybackAction[]>([]);
  private automatedRecordingSubscription?: Subscription;
  anyChangeInTestCaseStep: boolean = false;

  constructor(private injector: Injector) {
    this.initializeAutomatedRecording();
  }

  private initializeAutomatedRecording(): void {
    const sessionSocket = this.injector.get(SessionSocketService);

    this.automatedRecordingSubscription = sessionSocket.SocketMessagesText.pipe(
      filter(
        (message) =>
          message.startsWith("fetch_data_emit") || message.startsWith("record")
      )
    ).subscribe((message) => {
      if (message.startsWith("fetch_data_emit")) {
        const recordStep =
          message.length > 16 ? JSON.parse(message.substr(15)) : null;
        if (recordStep) {
          const allSteps = this.automatedStepsRecorded.value.slice();
          allSteps.push(recordStep);
          this.automatedStepsRecorded.next(allSteps);
        }
      }
    });
  }

  AddIssue(_issue: ILiveIssueR): void {
    const allIssues = this.IssuesCreated.getValue();
    allIssues.push(_issue);
    this.IssuesCreated.next(allIssues);
  }

  AddIssues(_issues: ILiveIssueR[]): void {
    let allIssues = this.IssuesCreated.getValue();
    allIssues = allIssues.concat(_issues);
    this.IssuesCreated.next(allIssues);
  }

  GetIssuesCreatedCount(): number {
    return this.IssuesCreated.getValue().length;
  }

  hasCreatedAnIssue(): boolean {
    return this.IssuesCreated.getValue().length > 0;
  }

  ResetIssues(): void {
    this.IssuesCreated.next([]);
  }

  ResetManualSteps(): void {
    this.manualStepsRecorded.next([]);
  }

  ResetAutomatedRecording() {
    this.automatedStepsRecorded.next([]);
  }

  ngOnDestroy(): void {
    if (this.automatedRecordingSubscription) {
      this.automatedRecordingSubscription.unsubscribe();
    }
  }
}
