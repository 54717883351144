import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OverlayService {
    overlay: BehaviorSubject<boolean> = new BehaviorSubject(true);
    sessionSocketConnected: BehaviorSubject<boolean> = new BehaviorSubject(false);
    loading: Subject<boolean> = new Subject();
    heading: Subject<boolean> = new Subject();
    headingText: Subject<string> = new Subject();
    isShowError: Subject<boolean> = new Subject();
    tryAgainDisable: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor() { }

    disableTryAgain() {
        this.tryAgainDisable.next(true);
    }

    showError() {
        this.isShowError.next(true);
    }

    ShowOverlay() {
        this.overlay.next(true);
    }

    SetOverlayText(text: string) {
        this.headingText.next(text);
    }

    HideLoading() {
        this.loading.next(false);
    }

    HideHeading() {
        this.heading.next(false);
    }

    HideOverlay() {
        this.overlay.next(false);
    }

    sessionConnected() {
        this.sessionSocketConnected.next(true)
    }
}
