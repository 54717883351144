// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl:
    "https://apigateway.happyriver-eac353b2.eastus2.azurecontainerapps.io",
  // baseUrl: 'https://api.sofy.ai',
  AUTH_TOKEN: "67935C5B-A009-4DC8-9EC6-2F8E9D58E6DC",
  parserURL: `https://status.sofy.ai:3000/parse`,
  proxyURL: `https://ca-proxy-stag-eastus2-001.happyriver-eac353b2.eastus2.azurecontainerapps.io/fetch/`,
  dataDogEnv: "local",
  dataDogService: "Portal-Local",
  b2c: {
    authorityDomain: "sofydev.onmicrosoft.com",
    clientId: "1e1c7825-42ae-45b8-ba06-b5e88ff6902a", // This is the ONLY mandatory field that you need to supply.
    knownAuthorities: "authdev.sofy.ai", // Mark your B2C tenant's domain as trusted.
    // redirectUri: "http://localhost:4200/auth",
    redirectUri: "https://devlab.sofy.ai/auth",
    endpoint:
      "https://apigateway.happyriver-eac353b2.eastus2.azurecontainerapps.io",
    scopeRead: "https://sofydev.onmicrosoft.com/sofydev-api/sofydev.read",
    scopeWrite: "https://sofydev.onmicrosoft.com/sofydev-api/sofydev.write",
    signUpSignIn:
      "https://authdev.sofy.ai/sofydev.onmicrosoft.com/B2C_1A_HRD_SSO_AL",

    resetPassword:
      "https://authdev.sofy.ai/sofydev.onmicrosoft.com/B2C_1A_PASSWORDRESET",

    verifyEmail:
      "https://authdev.sofy.ai/sofydev.onmicrosoft.com/B2C_1A_EMAIL_VERIFICATION",

    childUser:
      "https://authdev.sofy.ai/sofydev.onmicrosoft.com/B2C_1A_INV_REDEEM",

    passwordChange:
      "https://authdev.sofy.ai/sofydev.onmicrosoft.com/B2C_1A_PASSWORDCHANGE",
  },
  Environment: "LOCAL",
  DD_RUM_CLIENT_TOKEN: "pub5657b27074da19c848c3120bc1696ba9",
  DD_RUM_APP_ID: "a2e90ec9-9deb-4bdf-b2cc-ed8816e4f153",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
