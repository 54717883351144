import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";

import { ToasterService } from "./toaster.service";
import {
  INTERNET_STATUS,
  INTERNET_STATUS_EVENTS,
  PROJECT_NAME,
} from "../../shared/constants/constants";
import { SpeedTestService } from "ng-speed-test";
import { DataDogService } from "./dataDog.service";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    SofyAuth: "67935C5B-A009-4DC8-9EC6-2F8E9D58E6DC",
  }),
};
@Injectable({
  providedIn: "root",
})
export class InternetService {
  status = INTERNET_STATUS.CONNECTED;
  internet$: BehaviorSubject<any> = new BehaviorSubject<any>(this.status);
  isCheckingInternetSpeed = false;
  isOnceOffline = false;
  userGuid = "";
  sessionGuid = "";
  email = "";
  deviceName = "";
  deviceRegion = "";
  startTime = 0.0;
  portalStartTime = 0.0;
  platForm = "";
  isSlowInternetDetected = false;
  isStartSpeedEventSent = false;
  speedInterval: any;
  connectivityInterval: any;

  constructor(
    private speedTestService: SpeedTestService,
    private toaster: ToasterService,
    private dataDogService: DataDogService,
    private http: HttpClient
  ) {
    // this.checkSpeed();
    this.checkConnectivity();

    // this.speedInterval = setInterval(() => this.checkSpeed(), 20000);
    this.connectivityInterval = setInterval(
      () => this.checkConnectivity(),
      5000
    );
  }

  normalInternetDetected() {
    this.status = INTERNET_STATUS.CONNECTED;
  }

  announce() {
    // broadcast/announce
    this.internet$.next(this.status);

    const { SLOW, COULD_NOT_DETECT } = INTERNET_STATUS;
    if ([SLOW, COULD_NOT_DETECT].includes(this.status)) {
      this.isSlowInternetDetected = true;
      // this.toaster.ShowToastMessage("Slow internet detected", 0, 600000);
    } else if (this.isSlowInternetDetected) {
      this.isSlowInternetDetected = false;
      this.toaster.clearToastMessage();
    }
  }

  checkConnectivity() {
    this.speedTestService.isOnline().subscribe((isOnlineNow) => {
      if (this.isOnceOffline) {
        if (isOnlineNow) {
          this.isOnceOffline = false;
          setTimeout(() => {
            this.dataDogService.sendMessage("Internet Connected", {
              userGUiD: this.userGuid,
              deviceName: this.deviceName,
              sessionGuid: this.sessionGuid,
              email: this.email,
            });
            this.toaster.ShowToastMessage("Internet Connected", 1, 6000);
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }, 1000);
          this.status = INTERNET_STATUS.CONNECTED;
        }
      } else {
        if (!isOnlineNow) {
          this.isOnceOffline = true;
          this.dataDogService.sendMessage("Internet Disconnected", {
            userGUiD: this.userGuid,
            deviceName: this.deviceName,
            sessionGuid: this.sessionGuid,
            email: this.email,
          });
          this.toaster.ShowToastMessage(
            "Please check your internet connection",
            0,
            10000000
          );

          this.status = INTERNET_STATUS.DISCONNECTED;
        }
      }

      this.announce();
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.speedInterval);
    clearInterval(this.connectivityInterval);
  }
}
