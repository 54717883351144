export enum Actions {
  NO_OP = 0,
  APP_LAUNCH = 1,
  BACK_KEY = 2,
  SWIPE = 3,
  SEEK_BAR = 4,
  CLICK = 5,
  LONG_CLICK = 6,
  SET_TEXT = 7,
  UNEXPECTED_SCREEN = 8,
  APP_CLOSED = 9,
  LOADING_WAIT = 10,
  ENTER_KEY = 11,
  RESET_APP = 12,
  PICK_DATE = 13,
  EXPECTED = 14,
  GOOGLEPERMISSIONS = 15,
  HOME_KEY = 16,
  // ASSERTION = 17,
  ASSERT_EXISTS_EXACT_MATCH = 18,
  ASSERT_EQUALS_EXACT_MATCH = 19,
  ASSERT_NOT_EQUALS_EXACT_MATCH = 20,
  ASSERT_EXISTS_SIMILAR = 21,
  ASSERT_EQUALS_SIMILAR = 22,
  ASSERT_NOT_EQUALS_SIMILAR = 23,
  ASSERT_ENABLED = 25,
  ASSERT_NOT_ENABLED = 26,
  ASSERT_CHECKED = 27,
  ASSERT_UNCHECKED = 28,
  CLEAR_TEXT = 24,
  TOGGLE_WIFI = 29,
  TIME_DELAY = 25,
  DOUBLE_CLICK = 26,
  ROTATE_DEVICE = 30,

  SET_TEST_DATA_VARIABLE = 33,
  ASSERT_TEST_DATA_VARIABLE_EXACT_MATCH = 34,
  SET_DYNAMIC_TEST_CASE_VARIABLE = 42,
  SET_API_STEP_VARIABLE = 45,
  SWITCH_NETWORK = 35,
}

export enum LabScenarioMode {
  IDLE = 0,
  RECORD = 1,
  PLAYBACK = 2,
  MANUAL_TEST = 3,
  PAUSED = 4,
  REPORT_UPLOADING = 5,
}

export enum ActionEvents {
  MouseInput = 0,
  PerformAction = 1,
  StartRecording = 2,
  Playback = 3,
  EndIde = 4,
  FetchData = 5,
  GetCurrentMode = 6,
  UploadApp = 7,
  DeviceLogs = 8,
  UploadScenario = 9,
  SaveScenario = 10,
  KeyIputEvent = 11,
  FetchComponents = 15,
}

export enum BrowserMappings {
  "Google Chrome" = 0,
  "Mozilla Firefox" = 1,
  "Microsoft Edge" = 2,
  "Safari" = 3,
  "Opera" = 4,
}

export enum PlaybackActionStates {
  WAITING = 0,
  PASS = 1,
  FAIL = 2,
  WARNING = 3,
  UNSET = 4,
}

export enum PlaybackStates {
  START = 6,
  DOWNLOAD = 0,
  PLAYING = 1,
  SAVING = 2,
  UPLOADING = 3,
  COMPLETED = 4,
  IDLE = 5,
}

export enum Asserts {
  ASSERT_EXISTS_EXACT_MATCH = 18,
  ASSERT_EQUALS_EXACT_MATCH = 19,
  ASSERT_NOT_EQUALS_EXACT_MATCH = 20,
  ASSERT_EXISTS_SIMILAR = 21,
  ASSERT_EQUALS_SIMILAR = 22,
  ASSERT_NOT_EQUALS_SIMILAR = 23,
  ASSERT_ENABLED = 25,
  ASSERT_NOT_ENABLED = 26,
  ASSERT_CHECKED = 27,
  ASSERT_UNCHECKED = 28,
  ASSERT_TEST_DATA_VARIABLE_EXACT_MATCH = 34,
}

export enum SubscriptionEnums {
  LIVE = 1,
  AUTOMATE = 2,
  TRAIL = 3,
}


export enum PORTAL_URLS {
  LOCALHOST = 'http://localhost:4100/',
  DEV = 'https://devtest.sofy.ai/',
  PORTAL = 'https://portal-sofy-stag-01-dev-01.azurewebsites.net/',
  PRE_PROD = 'https://portal-sofy-stag-01-pre-prod.azurewebsites.net/',
  PRODUCTION = 'https://portal.sofy.ai/',
}

export enum HOSTNAMES {
  LOCALHOST = 'localhost',
  DEV = 'devlab.sofy.ai',
  PORTAL = 'lab-sofy-stag-01-dev-01.azurewebsites.net',
  PRE_PROD = 'lab-sofy-stag-01-pre-prod.azurewebsites.net',
  PROD = 'lab.sofy.ai',
}
