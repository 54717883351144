import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoggingService {
  RealTimeIdeLog: Subject<string> = new Subject<string>();

  private logHistory: string[] = [];
  logHistoryUpdated: Subject<string[]> = new Subject<string[]>();

  constructor() {}
  sendLog(message: string) {
    this.logHistory.push(message);
    this.logHistoryUpdated.next([...this.logHistory]);
    this.RealTimeIdeLog.next(message);
  }

  getLogHistory() {
    return this.logHistory;
  }
}
