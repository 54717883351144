import { Injectable } from '@angular/core';
import { datadogLogs } from '@datadog/browser-logs'

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DataDogService {
  constructor() {
    datadogLogs.init({
      clientToken: 'pub95ca865b6697f5cd7d9d024912ddab63',
      site: 'datadoghq.com',
      forwardErrorsToLogs: true,
      service: environment.dataDogService,
      sessionSampleRate: 100,
      env: environment.dataDogEnv
    })
  }

  sendMessage(message: string, detail: any) {
    try {
      datadogLogs.logger.info(message, detail)
    } catch (ex: any) {
      console.log('Error in logging')
      datadogLogs.logger.error('Error occurred', { team: 'myTeam' }, ex)
    }
  }
}
