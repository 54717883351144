import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import { ILiveIssueR } from "../interfaces/LiveIssueR";
import { IManualTestCase } from "../interfaces/ManualTestCase";
import {
  IManualTestCaseR,
  ManualTestCaseR,
} from "../interfaces/ManualTestCaseR";
import { IManualTestCaseResult } from "../interfaces/ManualTestCaseResult";

import { IReleasesR } from "../interfaces/ReleaseR";
import { IReleaseBuildR } from "../interfaces/ReleaseBuildR";
import { ILiveIssueC } from "../interfaces/LiveIssueC";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    SofyAuth: "67935C5B-A009-4DC8-9EC6-2F8E9D58E6DC",
  }),
};

@Injectable({
  providedIn: "root",
})
export class SofyLiveService {
  /*
    The image we see in issue create modal (sanitized)
  */
  issueCreateModalImageData_ToDisplay: BehaviorSubject<any> =
    new BehaviorSubject(null);
  /*
    The image we post with issue creation (blob)
  */
  issueCreateModalImageData_BLOB: BehaviorSubject<any> = new BehaviorSubject(
    null
  );
  /*
    Data required by issuecreate modal to create an issue
  */
  issueCreateModal_ModelData: BehaviorSubject<any> = new BehaviorSubject(null);
  /*
    Device Log URL required in issue create modal
  */
  issueCreateModal_DeviceLogUrl: BehaviorSubject<string> = new BehaviorSubject(
    null
  );
  /*
    Bug Report URL required in issue create modal
  */
  issueCreateModal_BugReportUrl: BehaviorSubject<string> = new BehaviorSubject(
    null
  );
  /*
    A Behavior subject that tells the issue create modal to end testing or continue manual testing
  */
  issueCreateModal_EndManualTestingAfterCreation: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  /*
    Since IDE only emits issueid in actionvalue we keep track of issues created during manual testing and then transform the data
    with the help of this behavior subject
  */
  ManualTestingIssuesCreated: BehaviorSubject<ILiveIssueR> =
    new BehaviorSubject(null);

  applicationGuid: BehaviorSubject<string> = new BehaviorSubject(null);
  manualDataActionStep: BehaviorSubject<any> = new BehaviorSubject(null);
  userSubscriptionData: BehaviorSubject<any> = new BehaviorSubject(null);
  userSubscriptionSummaryData: BehaviorSubject<any> = new BehaviorSubject(null);

  isImportedTestCaseManual: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  isAzureDevopsIntegrated: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  isJiraIntegrated: BehaviorSubject<boolean> = new BehaviorSubject(false);
  currentManualTestRunResult: BehaviorSubject<Number> = new BehaviorSubject(
    null
  );

  liveDemoTestCaseId: number;
  liveDemoTestResultId: number;
  liveDemoapplicationGUID: string;
  liveDemoapplicationID: number;
  AzureDevopsIntegrationSettings: any;

  constructor(private http: HttpClient) {}

  /**
   * Create Manual Test Case
   * @param userGUID creator's User Guid
   * @param applicationGuid Application Guid
   * @param manualTestCase Manual Test Case
   */
  CreateTestManalCase(
    userGUID: string,
    applicationGuid: string,
    manualTestCase: IManualTestCase
  ) {
    manualTestCase = { ...manualTestCase, UserGuid: userGUID };
    return this.http.post<IManualTestCaseR[]>(
      environment.baseUrl + `/api/Applications/${applicationGuid}/Tests`,
      manualTestCase,
      httpOptions
    );
  }

  /**
   * Get Manual Test Cases for application
   * @param applicationGuid Application Guid
   * @param lastLiveTestCaseId last test case id to paginate from
   */
  GetManualTestCase(applicationGuid: string, lastLiveTestCaseId: string = "") {
    let url =
      environment.baseUrl + `/api/Applications/${applicationGuid}/Tests`;
    // tslint:disable-next-line: triple-equals
    if (lastLiveTestCaseId != "") {
      url += "?LastLiveTestCaseId=" + lastLiveTestCaseId;
    }
    return this.http.get<IManualTestCaseR[]>(url, httpOptions);
  }

  GetManualTestCaseNode(appId: number, _pageNo: number) {
    let url =
      environment.baseUrl + `/mobile-microservice/manual-tests/${appId}`;
    // tslint:disable-next-line: triple-equals
    return this.http.get<ManualTestCaseR>(url, httpOptions);
  }

  /**
   * Update a Manual Test Case
   * @param applicationGuid Application Guid
   * @param userGuid User Guid
   * @param manualTestId Manual Test Id
   * @param manualTestCaseData Update Data as IManualTestCase
   */
  UpdateManualTestCase(
    applicationGuid: string,
    userGuid: string,
    manualTestId: string,
    manualTestCaseData: IManualTestCase
  ) {
    manualTestCaseData = { ...manualTestCaseData, UserGuid: userGuid };
    return this.http.put(
      environment.baseUrl +
        `/api/Applications/${applicationGuid}/Tests/${manualTestId}`,
      manualTestCaseData,
      httpOptions
    );
  }

  /**
   * Manual Test Case Result IDS
   * Failed = 0, Passed = 1, Incomplete = 2, In Progress = 3
   */
  /**
   * Create result for the manual test case
   * @param userGuid Users Guid
   * @param executorUserGuid Users Guid
   * @param applicationGuid Application Guid
   * @param applicationPkgGuid Application Package Guid
   * @param liveTestCaseId Live Test Case Id
   * @param deviceId Device Id
   * @param JsonUrl JSON Url
   * @param testExecutionTime Test case execution time
   * @param testResult true or false
   * @param releaseBuildId Release build id
   */
  CreateManualTestCaseResult(
    executorUserID: number,
    applicationGuid: string,
    AppPackageID: number,
    liveTestCaseId: number,
    deviceId: number,
    ReleaseModelID: number,
    deviceSerial: string
  ) {
    const newTestCaseResult = {
      AppPackageID: AppPackageID,
      DeviceId: deviceId,
      JSONUrl: "-",
      TestExecutionTime: 1,
      TestResult: 3,
      ExecutorUserID: executorUserID,
      DeviceSerial: deviceSerial,
    } as IManualTestCaseResult;

    return this.http.post<any>(
      environment.baseUrl +
        `/mobile-microservice/manual-test/release/${ReleaseModelID}/runs/${liveTestCaseId}`,
      newTestCaseResult,
      httpOptions
    );
  }

  /**
   * Get releases by app guid
   * @param applicationGuid To retrieve releases in string
   */
  GetReleases(applicationGuid: string) {
    return this.http.get<{ data: IReleasesR[]; message: string }>(
      environment.baseUrl +
        `/application-microservice/releases/${applicationGuid}`,
      httpOptions
    );
  }

  /**
   * Retrieve release builds by release id
   * @param applicationGuid Application Guid in string
   * @param releaseId in number
   */
  GetReleaseBuilds(applicationGuid: string, releaseId: number) {
    return this.http.get<{ data: IReleaseBuildR[]; message: string }>(
      environment.baseUrl +
        `/application-microservice/release/${releaseId}/builds/${applicationGuid}`,
      httpOptions
    );
  }

  CreateDefaultRelease(applicationguid: string, sDate: string, eDate: string) {
    return this.http.post<any[]>(
      environment.baseUrl +
        `/api/Applications/${applicationguid}/CreateDefaultRelease`,
      {
        StartDate: sDate,
        EndDate: eDate,
        UserSystemTime: this.getUserSystemTime(),
      },
      httpOptions
    );
  }

  getUserSystemTime(): string {
    const dt = new Date();
    const dtstring =
      dt.getFullYear() +
      "-" +
      this.pad(dt.getMonth() + 1) +
      "-" +
      this.pad(dt.getDate()) +
      " " +
      this.pad(dt.getHours()) +
      ":" +
      this.pad(dt.getMinutes()) +
      ":" +
      this.pad(dt.getSeconds());
    return dtstring;
  }

  pad(num: number): string {
    return ("00" + num).slice(-2);
  }

  /**
   * Create an Issue During manual recording
   * @param UserGuid1 user guid of creator
   * @param AppPackageGUID1 application package guid
   * @param DeviceId1 device id in number
   * @param LiveIssueType MUST BE PRODUCTION OR TEST
   * @param ssInBase64 Image in BASE 64
   * @param releaseModelId1 current release id in number
   * @param liveIssueTitle Title of issue
   * @param BugReport1 Bug Report URL
   * @param liveIssueDescription issue description in url
   */
  CreateAdHocIssue(
    UserID: number,
    AppPackageID: number,
    ApplicationID: number,
    deviceSerial: string,
    liveIssueTitle: string,
    LiveIssueType1: string,
    ssInBase64: string,
    releaseModelId1: number,
    liveIssueDescription: string,
    BugReport1: string = "-",
    logUrl1: string = "-"
  ) {
    const data = {
      BugReport: BugReport1,
      AppPackageId: AppPackageID,
      UserId: UserID,
      device_serial: deviceSerial,
      ReleaseModelID: releaseModelId1,
      LiveIssueDescription: liveIssueDescription,
      LiveIssueTitle: liveIssueTitle,
      LiveIssueType: LiveIssueType1,
      Logs: logUrl1,
      Screenshot: ssInBase64,
    };
    return this.http.post<any>(
      environment.baseUrl +
        `/mobile-microservice/manual-issues/${ApplicationID}`,
      data,
      httpOptions
    );
  }
  /**
   * Update an Issue During manual recording
   * @param TestCaseId1 Test Case Id in number
   * @param TestCaseResultId1 Test case result id in number
   * @param UserGuid1 user guid of creator
   * @param AppPackageGUID1 application package guid
   * @param DeviceId1 device id in number
   * @param LiveIssueType MUST BE PRODUCTION OR TEST
   * @param ssInBase64 Image in BASE 64
   * @param releaseModelId1 current release id in number
   * @param liveIssueTitle Title of issue
   * @param BugReport1 Bug Report URL
   * @param liveIssueDescription issue description in url
   */
  UpdateLiveIssue(
    LiveIssueId: number,
    TestCaseId1: number,
    TestCaseResultId1: number,
    UserGuid1: string,
    AppPackageGUID1: string,
    ApplicationGUID: string,
    DeviceId1: number,
    LiveIssueType1: string,
    ssInBase64: string,
    releaseModelId1: number,
    liveIssueTitle: string,
    liveIssueDescription: string,
    BugReport1: string = "-",
    logUrl1: string = "-"
  ) {
    const data = {
      UserGuid: UserGuid1,
      AppPackageGuid: AppPackageGUID1,
      DeviceID: DeviceId1,
      LiveIssueType: LiveIssueType1,
      Logs: logUrl1,
      Screenshot: ssInBase64,
      ReleaseModelID: releaseModelId1,
      BugReport: BugReport1,
      LiveIssueTitle: liveIssueTitle,
      LiveIssueDescription: liveIssueDescription,
    } as ILiveIssueC;
    return this.http.put(
      environment.baseUrl +
        `/api/Applications/${ApplicationGUID}/Tests/${TestCaseId1}/TestResult/${TestCaseResultId1}/Issue/${LiveIssueId}`,
      data,
      httpOptions
    );
  }

  updateLiveTestCase(livetestcaseid, result, applicationGuid: string) {
    const data = {
      LiveTestCaseId: livetestcaseid,
      TestResultStatus: result,
    };
    return this.http
      .post<any[]>(
        `${environment.baseUrl}/integration-microservice/azure/${applicationGuid}/test-result`,
        data,
        httpOptions
      )
      .pipe(
        map((it) => {
          return it[0];
        })
      );
  }

  openIssueAtAzure(
    IssueID,
    IssueType,
    devicename,
    packagename,
    version,
    osname,
    livetestcaseid,
    devicelog: string = "-",
    screenshot,
    applicationGuid: string,
    projectName: string
  ) {
    const data = {
      IssueID: IssueID,
      IssueType: IssueType,
      DeviceName: devicename,
      PackageName: packagename,
      Version: version,
      OSName: osname,
      LiveTestCaseId: livetestcaseid,
      Devicelog: devicelog,
      ScreenShot: screenshot,
      ProjectName: projectName,
    };
    return this.http
      .post<any[]>(
        `${environment.baseUrl}/integration-microservice/azure/${applicationGuid}/issue`,
        data,
        httpOptions
      )
      .pipe(
        map((it) => {
          return it[0];
        })
      );
  }

  getAzureDevopsIntegration(applicationGuid) {
    return this.http
      .get<any>(
        `${environment.baseUrl}/integration-microservice/azure/${applicationGuid}`,
        httpOptions
      )
      .pipe(
        map((res) => {
          if (res.data.length > 0) {
            this.isAzureDevopsIntegrated.next(true);
            this.AzureDevopsIntegrationSettings = {
              AzureURL: res.data[0].PlatformUri,
              AzureAccessToken: res.data[0].ProjectKey,
            };
          } else {
            this.isAzureDevopsIntegrated.next(false);
            this.AzureDevopsIntegrationSettings = null;
          }
          return res.data[0];
        })
      );
  }

  getAzureDevopsProjectList(applicationGuid) {
    if (this.AzureDevopsIntegrationSettings) {
      return this.http
        .post<any>(
          `${environment.baseUrl}/integration-microservice/azure/${applicationGuid}/projects`,
          this.AzureDevopsIntegrationSettings,
          httpOptions
        )
        .pipe(
          map((res) => {
            let result = res.data as any[];
            return result.map((x) => {
              x = {
                Name: x.name,
                Key: x.name,
                IssueTypeId: null,
              };
              return x;
            });
          })
        );
    }
    return of([]);
  }

  getJiraIntegration(applicationGuid) {
    return this.http
      .get<any>(
        `${environment.baseUrl}/integration-microservice/jira/${applicationGuid}`,
        httpOptions
      )
      .pipe(
        map((res) => {
          if (res.data.length > 0) {
            this.isJiraIntegrated.next(true);
          } else {
            this.isJiraIntegrated.next(false);
          }
          return res.data[0];
        })
      );
  }

  getJiraProjectList(applicationGuid) {
    return this.http.get<any[]>(
      `${environment.baseUrl}/integration-microservice/jira/${applicationGuid}/projects`,
      httpOptions
    );
  }

  openIssueAtJira(
    IssueID,
    IssueType,
    devicename,
    packagename,
    version,
    osname,
    key,

    issueTypeId,
    devicelog: string = "-",
    screenshot,
    applicationGuid: string
  ) {
    const data = {
      IssueID: IssueID,
      IssueType: IssueType,
      DeviceName: devicename,
      PackageName: packagename,
      Version: version,
      OSName: osname,
      Key: key,

      Devicelog: devicelog,
      ScreenShot: screenshot,
      IssueTypeId: issueTypeId,
    };
    return this.http
      .post<any[]>(
        `${environment.baseUrl}/integration-microservice/jira/${applicationGuid}/issue`,
        data,
        httpOptions
      )
      .pipe(
        map((it) => {
          return it[0];
        })
      );
  }

  ConvertManualTestCaseToAutomatedTestCase(
    _ScenarioName: string,
    _SofyLiveUserId: string,
    _ReleaseModelId: number,
    _TestCaseResultId: number,
    _platformId: number
  ): Observable<any> {
    const postdata = {
      ScenarioName: _ScenarioName,
      SofyLiveUserId: _SofyLiveUserId,
      ReleaseId: _ReleaseModelId.toString(),
      TestCaseResultId: _TestCaseResultId.toString(),
      PlatformId: _platformId.toString(),
    };
    return this.http.post(
      environment.baseUrl + `/api/Scenarios/ConvertManualToAutomated`,
      postdata,
      httpOptions
    );
  }
}
