import { Injectable, Injector } from "@angular/core";
import { BehaviorSubject, Observable, EMPTY, Subscription } from "rxjs";
import { ApplicationsService } from "./applications.service";
import { IInstallableApplicationIDE } from "../interfaces/InstallableApplicationIDE";
import { IReleasesR } from "../interfaces/ReleaseR";
import { map } from "rxjs/operators";
import { IReleaseBuildR } from "../interfaces/ReleaseBuildR";
import * as moment from "moment";

import { SofyLiveService } from "src/app/core/services/sofylive.service";

@Injectable({
  providedIn: "root",
})
export class ReleaseService {
  CurrentApp: IInstallableApplicationIDE;
  CurrentRelease: BehaviorSubject<IReleasesR> = new BehaviorSubject<IReleasesR>(
    null
  );
  CurrentReleaseBuild: BehaviorSubject<IReleaseBuildR> =
    new BehaviorSubject<IReleaseBuildR>(null);
  ReleaseDataReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null
  );

  applicationsService: ApplicationsService;
  private appChangeSubscription: Subscription;

  constructor(
    private injector: Injector,
    private sofyLiveService: SofyLiveService
  ) {}
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.UpdateReleases();
  }
  Init() {
    if (this.appChangeSubscription !== undefined) {
      return;
    }

    this.applicationsService = this.injector.get(ApplicationsService);
    this.appChangeSubscription =
      this.applicationsService.SelectedApplication.subscribe((app) => {
        if (
          app != null &&
          (!this.CurrentApp ||
            this.CurrentApp.ApplicationId !== app.ApplicationId)
        ) {
          this.CurrentApp = app;
          this.ReleaseDataReady$.next(false);
          this.SwitchCurrentRelease(app).subscribe(
            (release) => {
              this.CurrentRelease.next(release);
              this.SwitchReleaseBuild(app, release).subscribe(
                (releaseBuild) => {
                  this.CurrentReleaseBuild.next(releaseBuild);
                  this.ReleaseDataReady$.next(true);
                },
                (_err) => this.ReleaseDataReady$.next(false)
              );
            },
            (_err) => this.ReleaseDataReady$.next(false)
          );
        }
      });
  }

  public UpdateReleases() {
    if (this.CurrentApp) {
      this.ReleaseDataReady$.next(false);
      this.appChangeSubscription = this.SwitchCurrentRelease(
        this.CurrentApp
      ).subscribe(
        (release) => {
          this.CurrentRelease.next(release);

          if (release != null) {
            this.SwitchReleaseBuild(this.CurrentApp, release).subscribe(
              (releaseBuild) => {
                this.CurrentReleaseBuild.next(releaseBuild);
                const appService = this.injector.get(ApplicationsService);
                appService.UpdateIDESessionApplicationTrack();
                this.ReleaseDataReady$.next(true);
              },
              (_err) => this.ReleaseDataReady$.next(false)
            );
          }
        },
        (_err) => this.ReleaseDataReady$.next(false)
      );
    }
  }

  private SwitchCurrentRelease(
    application: IInstallableApplicationIDE
  ): Observable<IReleasesR> {
    return this.sofyLiveService.GetReleases(application.ApplicationGUID).pipe(
      map((releaseList) => {
        releaseList.data = releaseList.data.filter((it: any) => {
          const eDate = moment
            .utc(it.EndDate)
            .local()
            .format("YYYY-MM-DD HH:mm");
          const sDate = moment
            .utc(it.StartDate)
            .local()
            .format("YYYY-MM-DD HH:mm");
          const d = moment().local().format("YYYY-MM-DD HH:mm");
          if (d >= sDate && d <= eDate) {
            return it;
          }
        });
        if (releaseList.data[0]) {
          return releaseList.data[0];
        }
        return null;
      })
    );
  }

  private SwitchReleaseBuild(
    application: IInstallableApplicationIDE,
    release: IReleasesR
  ): Observable<IReleaseBuildR> {
    if (release != null && application != null) {
      return this.sofyLiveService
        .GetReleaseBuilds(application.ApplicationGUID, release.ReleaseModelId)
        .pipe(
          map((releaseBuilds) => {
            return releaseBuilds.data[0];
          })
        );
    } else {
      this.CurrentReleaseBuild.next(null);
      return EMPTY;
    }
  }

  /**
   * User System Time in Database Format
   */
  getUserSystemTime(): string {
    const dt = new Date();
    const dtstring =
      dt.getFullYear() +
      "-" +
      this.pad(dt.getMonth() + 1) +
      "-" +
      this.pad(dt.getDate()) +
      " " +
      this.pad(dt.getHours()) +
      ":" +
      this.pad(dt.getMinutes()) +
      ":" +
      this.pad(dt.getSeconds());
    return dtstring;
  }
  pad(num: number): string {
    return ("00" + num).slice(-2);
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.appChangeSubscription.unsubscribe();
  }
}
