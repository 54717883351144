import { Injectable, Injector } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable, noop, Subject } from "rxjs";
import { environment } from "../../../environments/environment";

import { tap } from "rxjs/operators";
import { OverlayService } from "./overlay.service";
import { ActivatedRoute } from "@angular/router";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    SofyAuth: "67935C5B-A009-4DC8-9EC6-2F8E9D58E6DC",
  }),
};
@Injectable({
  providedIn: "root",
})
export class SessionService {
  get SessionGUID(): string {
    const Val = this.SessionDataSub.getValue();

    if (Val) {
      return Val.SessionGUID;
    }
    return "";
  }

  get DeviceName(): string {
    const Val = this.SessionDataSub.getValue();

    if (Val) {
      return Val.FriendlyDeviceName || Val.DeviceName;
    }
    return "";
  }

  get OSVersion(): string {
    const Val = this.SessionDataSub.getValue();

    if (Val && Val.CodeName !== "") {
      return Val.CodeName + "-" + Val.BuildNumber;
    } else if (Val) {
      return Val.BuildNumber;
    } else {
      return "";
    }
  }

  get deviceSdkVersion(): number {
    const Val = this.SessionDataSub.getValue();

    if (Val) {
      return Number(Val.APILevel);
    } else {
      return 0;
    }
  }

  baseUrl = environment.baseUrl + "/api";
  CurrentPlatformId: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  isSofy3: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  SessionDataSub: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  ideActions: BehaviorSubject<string> = new BehaviorSubject<string>("-");
  sidebarTabsIndex: BehaviorSubject<string> = new BehaviorSubject<string>(
    "automateTest"
  );
  OnLeaveSofyLab: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  isRecordingPaused = false;
  UserGUID: any;
  UIChanges: Subject<any> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private injector: Injector
  ) {}

  UpdateSessionDetail(sessionGUID: any) {
    this.http
      .get<any>(
        environment.baseUrl + "/session-microservice/session/" + sessionGUID,
        httpOptions
      )
      .pipe(tap((data) => this.SessionDataSub.next(data.data)))
      .subscribe((_data) => noop);
  }

  updateSocketConnectionStatus(sessionGuid: any) {
    this.http
      .put<any>(
        `${environment.baseUrl}/session-microservice/session/${sessionGuid}/socket-connection`,
        {},
        httpOptions
      )
      .subscribe();
  }

  releaseSessionBySession(sessionGUID: any) {
    return this.http.post<any>(
      environment.baseUrl +
        `/session-microservice/session/${sessionGUID}/removeSession`,
      httpOptions
    );
  }

  GetScenariosByApplicationId(appId) {
    return this.http.get<any>(
      this.baseUrl + `/plugin/GetScenariosByApplicationId?appId=${appId}`,
      httpOptions
    );
  }

  getAppTestListOfRuns(
    applicationid: number,
    pageNo: number
  ): Observable<any[]> {
    const url = `/automation-microservice/test-case/${applicationid}/results?pageNo=${pageNo}`;
    return this.http.get<any[]>(environment.baseUrl + url, httpOptions);
  }

  getManualAppTestListOfRuns(
    applicationid: number,
    pageNo: number
  ): Observable<any[]> {
    const url = `/mobile-microservice/manual-tests/${applicationid}/manualLabResults?pageNo=${pageNo}`;
    return this.http.get<any[]>(environment.baseUrl + url, httpOptions);
  }

  getSessionStatus(sessionGUID) {
    return this.http.get<any>(
      environment.baseUrl + `/session-microservice/session/${sessionGUID}`,
      httpOptions
    );
  }

  ReleaseSessionWithEffect() {
    if (environment.Environment == "PRODUCTION") {
      window.DD_RUM.stopSessionReplayRecording();
    }

    return this.http.post<any>(
      environment.baseUrl +
        `/session-microservice/session/${this.SessionGUID}/removeSession`,
      {},
      httpOptions
    );
  }

  LabSessionFeedback(SessionFeedback, FeedbackComment) {
    this.http
      .put<any>(
        environment.baseUrl +
          `/session-microservice/session/${this.SessionGUID}/feedback`,
        {
          SessionFeedback,
          FeedbackComment,
        },
        httpOptions
      )
      .subscribe();
  }

  CreateDeviceAquireTimeBug() {
    let stringToPost = "";
    const data = this.SessionDataSub.getValue();
    // tslint:disable-next-line: forin
    for (const key in data) {
      stringToPost += key + ": " + data[key] + "\\n";
    }

    this.http
      .post<any>(
        this.baseUrl + "/IntegrationsController/Jira/CreateIssueOnJira",
        {
          Title: "Device Acquire Failure",
          Description: stringToPost,
        },
        httpOptions
      )
      .subscribe();
  }
}
