import { Component, OnInit, Inject } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { filter, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { SessionService } from "./core/services/session.service";
import { SessionSocketService } from "./core/services/session-socket.service";
import { ReleaseService } from "./core/services/release.service";
import { TimerService } from "./core/services/timer.service";
import { OverlayService } from "./core/services/overlay.service";
import { IToastMessage } from "./core/interfaces/IToastMessage";
import { SubscriptionService } from "./core/services/subscription.service";
import { RecordingService } from "./core/services/recording.service";
import { IdTokenClaims, PromptValue } from "@azure/msal-common";
import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  RedirectRequest,
  SsoSilentRequest,
} from "@azure/msal-browser";
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from "@azure/msal-angular";
import { b2cPolicies } from "./auth-config";

import { environment } from "src/environments/environment";

import "@datadog/browser-rum/bundle/datadog-rum";
import { LocalStorageService } from "./core/services/local-storage.service";

import { NgbTooltipConfig } from "@ng-bootstrap/ng-bootstrap";



type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string;
  tfp?: string;
};

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "Sofy Lab";
  DefaultApplicationId: number;
  toast: IToastMessage;
  PlatformId: number;
  userId: string;
  userGUID: string = "";
  lastTestRunID: number;
  isReleaseSessionAPICalled = false;
  testFlight: any;
  isIframe = false;
  loginDisplay = false;
  deviceName: string = "";
  sessionGUID: string = "";
  playBackListing: any = [];
  logHistory: any;

  // Subject to handle component destruction and unsubscribe observables
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private sessionService: SessionService,
    private releaseService: ReleaseService,
    private overlayService: OverlayService,
    private sessionSocketService: SessionSocketService,
    private subscriptionService: SubscriptionService,
    private timerService: TimerService,
    private route: ActivatedRoute,
    private recordingService: RecordingService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private localStorageService: LocalStorageService,
    private config: NgbTooltipConfig
  ) { }

  // Lifecycle hook to initialize the component
  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    this.initializeMonitoring();
    this.setupMSALSubscriptions();
    this.handleRouteParams();
    this.sendRecordingService();

    this.config.placement = "auto";
    this.config.container = "body";
    this.config.triggers = "hover";
    // this.config.animation = true;
  }

  // Lifecycle hook to handle component destruction
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  // Initialize Datadog monitoring if in production environment
  private initializeMonitoring(): void {
    if (environment.Environment === "PRODUCTION") {
      window.DD_RUM.init({
        applicationId: environment.DD_RUM_APP_ID,
        clientToken: environment.DD_RUM_CLIENT_TOKEN,
        site: "datadoghq.com",
        service: "portal-lab-sofy-angular",
        env: environment.Environment,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        trackFrustrations: true,
        defaultPrivacyLevel: "allow",
      });
    }
  }

  // Set up MSAL subscriptions to handle authentication events
  private setupMSALSubscriptions(): void {
    this.authService.instance.enableAccountStorageEvents();

    // Handle account added or removed events
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe(() => {
        this.handleAccountChanges();
      });

    // Handle interaction status changes
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
        this.getClaims(
          this.authService.instance.getActiveAccount()?.idTokenClaims
        );
      });

    // Handle successful authentication events
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            msg.eventType === EventType.SSO_SILENT_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        this.handleAuthenticationSuccess(result);
      });

    // Handle authentication failure events
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_FAILURE ||
            msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE ||
            msg.eventType === EventType.SSO_SILENT_FAILURE
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        this.handleAuthenticationFailure(result);
      });
  }

  // Handle account changes
  private handleAccountChanges(): void {
    if (this.authService.instance.getAllAccounts().length === 0) {
      window.self.close();
    } else {
      this.setLoginDisplay();
    }
  }

  // Handle successful authentication events
  private handleAuthenticationSuccess(result: EventMessage): void {
    let payload = result.payload as AuthenticationResult;
    let idtoken = payload?.idTokenClaims as IdTokenClaimsWithPolicyId;

    switch (idtoken.acr || idtoken.tfp) {
      case "b2c_1a_hrd_sso_al":
      case b2cPolicies.names.signUpSignIn:
        this.authService.instance.setActiveAccount(payload.account);
        break;

      case "b2c_1a_passwordchange":
      case b2cPolicies.names.passwordChange:
        this.silentLoginWithSignUpSignInPolicy(idtoken);
        break;

      case "b2c_1a_passwordreset":
      case b2cPolicies.names.resetPassword:
        this.reauthenticateWithNewPassword();
        break;

      case b2cPolicies.names.verifyEmail:
      case b2cPolicies.names.childUser:
        this.authService.instance.setActiveAccount(payload.account);
        break;
    }
  }

  // Handle authentication failure events
  private handleAuthenticationFailure(result: EventMessage): void {
    if (result.error && result.error.message.includes("AADB2C90118")) {
      let resetPasswordFlowRequest: RedirectRequest | PopupRequest = {
        authority: b2cPolicies.authorities.resetPassword.authority,
        scopes: [],
      };
      this.login(resetPasswordFlowRequest);
    }
  }


  // Perform silent login with the sign-up/sign-in policy
  private silentLoginWithSignUpSignInPolicy(
    idtoken: IdTokenClaimsWithPolicyId
  ): void {
    const originalSignInAccount = this.authService.instance
      .getAllAccounts()
      .find(
        (account: AccountInfo) =>
          account?.idTokenClaims?.oid === idtoken.oid &&
          account?.idTokenClaims?.sub === idtoken.sub &&
          [
            b2cPolicies.names.signUpSignIn,
            b2cPolicies.names.passwordChange,
          ].includes(
            (account?.idTokenClaims as IdTokenClaimsWithPolicyId).acr ||
            (account?.idTokenClaims as IdTokenClaimsWithPolicyId).tfp
          )
      );

    let signUpSignInFlowRequest: SsoSilentRequest = {
      authority: b2cPolicies.authorities.signUpSignIn.authority,
      account: originalSignInAccount,
    };

    this.authService.ssoSilent(signUpSignInFlowRequest);
  }

  // Re-authenticate the user with the new password
  private reauthenticateWithNewPassword(): void {
    let signUpSignInFlowRequest: RedirectRequest | PopupRequest = {
      authority: b2cPolicies.authorities.signUpSignIn.authority,
      prompt: PromptValue.LOGIN,
      scopes: [],
    };
    this.login(signUpSignInFlowRequest);
  }

  // Handle route parameters to set up session details
  private handleRouteParams(): void {
    this.route.queryParams.subscribe((params: any) => {
      if (this.areRequiredParamsPresent(params)) {
        const {
          sessionGUID,
          applicationid,
          platformId,
          userID,
          UserGUID,
          deviceName,
          isSofy3,
          parentGuid,
          sessionType,
        } = params;

        this.PlatformId = parseInt(platformId, 10);
        this.userId = userID;
        this.userGUID = UserGUID;
        this.DefaultApplicationId = parseInt(applicationid, 10);
        this.deviceName = deviceName;
        const isSofy3Value = isSofy3 ? parseInt(isSofy3) : 0;

        this.sessionService.isSofy3.next(isSofy3Value);
        this.updateSessionDetails(sessionGUID);
        this.initializeServices(sessionGUID, parentGuid);
        this.handleTestFlight(sessionType);
      }
    });

    this.checkSessionStatusWithDelay(50000);
  }

  private areRequiredParamsPresent(params: any): boolean {
    return params.sessionGUID && params.applicationid;
  }

  private updateSessionDetails(sessionGUID: string): void {
    this.sessionService.UpdateSessionDetail(sessionGUID);
  }

  private initializeServices(sessionGUID: string, parentGuid?: string): void {
    const sessionIdentifier = parentGuid ? parentGuid : sessionGUID;
    this.timerService.Init(sessionGUID);
    this.subscriptionService.Init(sessionIdentifier);
  }

  private handleTestFlight(sessionType: string): void {
    this.testFlight = sessionType;
    if (this.testFlight) {
      this.sessionSocketService.testFlightVariable.next(true);
    }
  }
  private checkSessionStatusWithDelay(delay: number): void {
    setTimeout(() => {
      this.sessionService
        .getSessionStatus(this.sessionService.SessionGUID)
        .subscribe((res) => {
          this.handleSessionStatusResponse(res);
        });
    }, delay);
  }

  private handleSessionStatusResponse(res: any): void {
    if (res.message !== "Session Fetched Successfully") {
      this.overlayService.SetOverlayText(
        "Could not acquire session. Please try another device."
      );
      this.overlayService.HideLoading();
      this.overlayService.ShowOverlay();
    } else {
      this.sessionService.UpdateSessionDetail(this.sessionService.SessionGUID);
    }
  }

  // Set the login display flag
  private setLoginDisplay(): void {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  // Check and set the active account if none is active
  private checkAndSetActiveAccount(): void {
    const activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[accounts.length - 1]);
    }
  }

  // Perform login with optional user flow request
  login(userFlowRequest?: RedirectRequest | PopupRequest): void {
    const interactionType = this.msalGuardConfig.interactionType;
    const authRequest = this.msalGuardConfig.authRequest;

    if (interactionType === InteractionType.Popup) {
      this.authService
        .loginPopup({ ...authRequest, ...userFlowRequest } as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    } else {
      this.authService.loginRedirect({
        ...authRequest,
        ...userFlowRequest,
      } as RedirectRequest);
    }
  }

  // Trigger the change password flow
  changePassword(): void {
    const changePasswordFlowRequest: RedirectRequest | PopupRequest = {
      authority: b2cPolicies.authorities.passwordChange.authority,
      scopes: [],
    };
    this.login(changePasswordFlowRequest);
  }

  // Perform logout for the active account
  logout(): void {
    const activeAccount =
      this.authService.instance.getActiveAccount() ||
      this.authService.instance.getAllAccounts()[0];
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({ account: activeAccount });
    } else {
      this.authService.logoutRedirect({ account: activeAccount });
    }
  }

  // Retrieve and process claims from the ID token
  private getClaims(claims: any): void {
    if (claims) {
      const user = { id: claims.sub, name: claims.name, email: claims.email };
      if (environment.Environment === "PRODUCTION") {
        window.DD_RUM.startSessionReplayRecording();
        window.DD_RUM.setUser(user);
      }
      this.afterClaim();
    }
  }

  // Actions to perform after retrieving claims
  private afterClaim(): void {
    this.timerService.timerEvents$.subscribe((data) => {
      if (data === "SIGTERM" && !this.timerService.isSessionEnded) {
        this.overlayService.SetOverlayText("Session Ended");
        this.overlayService.HideLoading();
        this.overlayService.ShowOverlay();
      }
    });

    this.sessionService.SessionDataSub.subscribe((sessionData) => {
      if (sessionData) {
        if (!sessionData.Expired) {
          this.sessionGUID = sessionData.SessionGUID;
          this.sessionSocketService.initializeSessionSocket(sessionData);
          this.releaseService.Init();
        } else {
          this.overlayService.SetOverlayText("Session Ended");
          this.overlayService.HideLoading();
          this.overlayService.ShowOverlay();
        }
      }
    });
  }

  // Send recording service information to the session socket service
  private sendRecordingService(): void {
    this.sessionSocketService.receivedRecordingService(this.recordingService);
  }
}
