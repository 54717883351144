import { Injectable } from "@angular/core";
import { IToastMessage } from "../interfaces/IToastMessage";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ToasterService {
  Toaster$: Subject<IToastMessage> = new Subject<IToastMessage>();

  constructor() { }
  ShowToastMessage(
    toastMessage: string,
    type = 0,
    duration: number = 5000,
    failedDetails: string = ""
  ) {
    this.Toaster$.next({
      message: toastMessage,
      visibilityDuration: duration,
      toastType: type,
      failedDetails: failedDetails,
    });
  }

  /**
   * Clear/hides the previous toast message
   */
  clearToastMessage() {
    this.Toaster$.next();
  }
}
