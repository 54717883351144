import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LocalStorageService {

	private isTutorialDone: BehaviorSubject<any> = new BehaviorSubject(0);
	private isTutorialShown: BehaviorSubject<any> = new BehaviorSubject(0);

	constructor() {
		this.isTutorialDone.next(Number(sessionStorage.getItem('isTutorialDone')));
		this.isTutorialShown.next(Number(sessionStorage.getItem('isTutorialShown')));
	}

	setValue(key: any, value: any) {
		localStorage.setItem(key, value);
	}

	getValue(key: any) {
		return localStorage.getItem(key);
	}

	removeValue(key: any) {
		localStorage.removeItem(key);
		return;
	}

}
